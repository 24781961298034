/* eslint-disable no-console */
import React, { Component } from 'react';

import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';

import { Formik, Form, Field } from 'formik';

import seo from 'tools/seo';

import userService from 'services/user';

import Input from 'components/Form/Input';
import { LoadingButton } from 'components/Loading';

import schema from './ResetPassword.schema';
import './ResetPassword.scss';

type Props = {
  match: Object,
};

class ResetPassword extends Component<Props> {
  state = {
    mode: false,
  };

  handleSubmit = (values, formikBag) => {
    const {
      match: { params },
    } = this.props;
    this.setState({ mode: 'loading' });
    userService
      .passwordReset(params.token, `password=${encodeURIComponent(values.plainPassword)}`)
      .then(() => {
        this.setState({ mode: 'success' });
      })
      .catch(error => {
        if (error && error.data && error.data.violations) {
          error.data.violations.forEach(err => {
            formikBag.setFieldError(err.propertyPath, err.message);
          });
        } else if (error && (error.status === 401 || error.status === 404)) {
          formikBag.setFieldError('plainPassword', 'Wrong token');
        } else if (error && error.status === 500) {
          formikBag.setFieldError('plainPassword', 'Service unavailable');
        }
        console.log(error);
        this.setState({ mode: false });
      });
  };

  render() {
    const { mode } = this.state;

    const initialValues = {
      password: null,
    };

    return (
      <div className="ResetPassword">
        <Helmet>
          {seo.title('Reseet Password - Flowfo')}
          {seo.noIndex()}
        </Helmet>
        <div className="ResetPassword-logo mb-3">
          <img src="/assets/images/flowfo.svg" alt="Flowfo" />
        </div>

        {mode === 'success' && (
          <div className="flowfont-thin fs-100 text-center text-neutral">
            Password reseted. You can now <Link to="/login">login</Link>.
          </div>
        )}
        {mode !== 'success' && (
          <>
            <div className="flowfont-thin fs-100 text-center text-neutral">You can now set your new password.</div>
            <div className="ResetPassword-form">
              <Formik initialValues={initialValues} validationSchema={schema} onSubmit={this.handleSubmit}>
                {() => (
                  <Form>
                    <Field name="plainPassword" type="password" label="Password" component={Input} />
                    <LoadingButton
                      className="mt-4 px-4 rounded-pill"
                      label="Reset password"
                      isFetching={mode === 'loading'}
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default ResetPassword;
